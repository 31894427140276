<template>
    <div class="w-full self-start">
        <ModalAddExclude
            v-if="modalAddExcludeVisible"
            :modalAddExclude="modalAddExclude"
            :employeeUuid="employeeUuid"
            :excludesDataUuid="excludesDataUuid"
            @refresh="getAll"
            @close="modalAddExclude = false"
            @closed="modalAddExcludeVisible = false"
        />

        <div class="card flex justify-between items-center">
            <h1>{{ $t('employee.exclude_this_employee_from_calendar_suggestions_for_specific_client') }}</h1>
            <el-button type="primary" :loading="$wait.is('loading')" @click="showAddModal">
                {{ $t('common.add') }}
            </el-button>
        </div>
        <div class="card">
            <el-table v-if="excludesData.length > 0" :data="excludesData" class="w-full">
                <el-table-column :label="$t('employee_details.client')">
                    <template slot-scope="scope">
                        {{ scope.row.client_name }} {{ scope.row.client_surname }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('employee_details.reason')" prop="reason" />
                <el-table-column width="40">
                    <template slot-scope="scope">
                        <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.delete')" placement="top">
                            <el-button type="danger" size="mini" @click="handleDelete(scope.row.uuid)">
                                <fa-icon :icon="['fas', 'trash']" fixed-width />
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <NoDataInformation :data="noDataInformation" waitKey="loading" />
        </div>
    </div>
</template>
<script>
export default {
    components: {
        ModalAddExclude: () => import(/* webpackChunkName: "EmployeeExclude/ModalAddExclude" */ './components/ModalAddExclude'),
    },

    data() {
        return {
            noDataInformation:      false,
            employeeUuid:           this.$route.params.uuid,
            excludesData:           [],
            excludesDataUuid:       [],
            modalAddExclude:        false,
            modalAddExcludeVisible: false,
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'exclude');
    },

    created() {
        this.$wait.start('loading');
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading');

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.employeeUuid}/excludes`);
            this.excludesData = data;
            this.noDataInformation = data.length;
            this.excludeOnlyUuid();

            this.$wait.end('loading');
        },

        async showAddModal() {
            this.modalAddExcludeVisible = true;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.modalAddExclude = true;
        },

        excludeOnlyUuid() {
            this.excludesDataUuid = this.excludesData.map(element => element.client_uuid);
        },

        handleDelete(uuid) {
            this.$confirm(this.$t('common.are_you_sure'), this.$t('common.warning'), {
                confirmButtonText: this.$t('common.yes'),
                cancelButtonText:  this.$t('common.cancel'),
                type:              'warning',
                beforeClose:       async (action, instance, done) => {
                    if (action === 'confirm') {
                        // @Filip
                        instance.confirmButtonLoading = true;
                        instance.closeOnClickModal = false;

                        try {
                            await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${uuid}/delete_exclude`);
                            this.$notify.success({ title: 'Success' });
                            this.getAll();
                        } catch {
                            return;
                        }

                        done();
                        instance.confirmButtonLoading = false;
                        instance.closeOnClickModal = true;
                    } else {
                        done();
                    }
                },
            });
        },
    },
};
</script>
